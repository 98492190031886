// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-submit-question-tsx": () => import("./../../../src/pages/submit-question.tsx" /* webpackChunkName: "component---src-pages-submit-question-tsx" */),
  "component---src-pages-teams-tsx": () => import("./../../../src/pages/teams.tsx" /* webpackChunkName: "component---src-pages-teams-tsx" */),
  "component---src-templates-archive-tsx": () => import("./../../../src/templates/Archive.tsx" /* webpackChunkName: "component---src-templates-archive-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/Event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-events-tsx": () => import("./../../../src/templates/Events.tsx" /* webpackChunkName: "component---src-templates-events-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/Tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

